import { useState, useEffect } from "react"

const useIntersection = (element, config) => {
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setState(entry.isIntersecting)
        observer.unobserve(element.current)
      }
    })

    element.current && observer.observe(element.current)
    const variableCleanup = element.current

    return () => observer.unobserve(variableCleanup)
  }, [element])

  return isVisible
}

export default useIntersection
