import "../../styles/section.css";
import "./compromiso.css";
import "../carta/carta.css";

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { Parallax } from "react-scroll-parallax";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import SectionLanding from "../../components/section/sectionLanding";
import Pilares from "../../components/compromiso/Pilares";

import Mask1 from "../../svg/masks/mask1.svg";
import Mask2 from "../../svg/masks/mask2.svg";
import Mask5 from "../../svg/masks/mask5.svg";
import Subrayado2 from "../../svg/subrayados/subrayado02.svg";
// import Floritura from "../../svg/florituras/floritura.svg"
import FlorituraHoja from "../../svg/florituras/florituraHoja.svg";
// import FlorituraSol from "../../svg/florituras/florituraSol.svg"

export default function IndexPage() {
	const { t } = useTranslation();

	return (
		<Layout footerText={t("¿Y si somos reales y no perfectos?")}>
			<Seo title={t("Compromiso")} />
			<div className="blackOverlay" id="compromisoOverlay" />
			<StaticImage
				src={`../../images/compromiso.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../images/compromiso.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<div className="landingSection">
				<SectionLanding
					title={t("Compromiso")}
					orbitalText={t("Responsabilidad")}
					section="compromiso"
				/>
			</div>

			{/* Importing masks to use them as clipping masks for backgrounds */}
			<Mask1 />
			<Mask2 />
			<Mask5 />

			<div id="compromisoIntro" className="section invertColors">
				<div>
					<div className="sectionLanding">
						<div>
							<div>
								<FlorituraHoja className="floritura" />
								<p>
									<Trans>Vegetales</Trans>
								</p>
								<h1>
									<Trans>Producto de cercanía</Trans>
								</h1>
								<p>
									<Trans>
										Procuramos apoyar el comercio local, obteniendo gran parte
										de nuestros productos de agricultores cercanos a nuestros
										restaurantes. De esta manera, conectamos a estos
										agricultores locales con la comunidad
									</Trans>
								</p>
							</div>
						</div>
					</div>
					<div className="sectionLanding">
						<div>
							<div>
								<FlorituraHoja className="floritura" />
								<p>
									<Trans>Vegetales</Trans>
								</p>
								<h1>
									<Trans>Producto Ecológico</Trans>
								</h1>
								<p>
									<Trans>
										Siempre que no sea posible garantizar el producto local, nos
										aseguramos de que los productos sean nacionales, de calidad,
										respeten el medio ambiente y contribuyan a desarrollar las
										zonas rurales
									</Trans>
								</p>
							</div>
						</div>
					</div>
					<div className="sectionLanding">
						<div>
							<div>
								<FlorituraHoja className="floritura" />
								<p>
									<Trans>Proteínas</Trans>
								</p>
								<h1>
									<Trans>Bienestar animal</Trans>
								</h1>
								<p>
									<Trans>
										Trabajamos mano a mano con nuestros ganaderos y nos
										aseguramos de que todas las proteínas que comerás en Begin
										serán de Bienestar Animal, excepto las Plant-based claro :)
									</Trans>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div id="conocenos" className="section">
        <div className="compromisoSection left">
          <div className="compromisoText">
            <h1>
              <Trans>Conócenos</Trans>
            </h1>
            <h3>
              <Trans>
                Bienvenid@. Somos Begin, un grupo de jóvenes con ganas de
                mejorar la experiencia gastronómica de cada uno de nosotros.
                Aquí podrás encontrar información sobre todo lo que hacemos y
                cómo lo hacemos, sin filtros. Te invitamos a descubrirnos
              </Trans>
            </h3>
          </div>
          <a href="/pdf.pdf" className="pdfLink">
            <Trans>Conocer más +</Trans>
            <Subrayado2 />
          </a>
        </div>
        <Parallax speed={-10} className="parallaxImg">
          <StaticImage
            className="backgroundImg"
            src={`../../images/compromismo-intro.jpg`}
            alt="Section background"
            layout="fullWidth"
          />
        </Parallax>
      </div> */}

			<div id="proximidad" className="section">
				<div className="blackOverlay" id="proximidadOverlay" />
				<StaticImage
					className="backgroundImg"
					src={`../../images/compromiso-proximidad.jpg`}
					alt="Section background"
					layout="fullWidth"
				/>
				<Parallax speed={-5}>
					<div className="compromisoSection left">
						<div className="compromisoText">
							<h1>
								<Trans>Proximidad y producto ecológico</Trans>
							</h1>
							<div>
								<p>
									<Trans>
										Los ingredientes vegetales con los que elaboramos nuestros
										platos son, en su mayoría, cosechados y recolectados por
										agricultores locales de en torno a nuestros restaurantes.
									</Trans>
								</p>
								<p>
									<Trans>
										Siempre que esto no sea posible, debido a condiciones
										climatológicas o ingredientes más endémicos, procuramos que
										los ingredientes que no sean locales, al menos sean
										nacionales y estén cultivados en plantaciones que respeten
										el planeta y puedan mantener un equilibrio con su entorno.
										Para nosotros lo más importante es garantizar la calidad de
										nuestros productos y trabajar mano a mano con nuestros
										agricultores para poder conectar los mejores productos con
										las comunidades en torno a nuestros restaurantes.
									</Trans>
								</p>
							</div>
						</div>
						<a href="/pdf.pdf" className="pdfLink">
							<Trans>Conocer más +</Trans>
							<Subrayado2 />
						</a>
					</div>
				</Parallax>
			</div>

			<div id="bienestarAnimal" className="section">
				<div className="blackOverlay" id="bienestarOverlay" />
				<StaticImage
					className="backgroundImg"
					src={`../../images/comprguay.jpg`}
					alt="Section background"
					layout="fullWidth"
				/>
				<Parallax speed={-5}>
					<div className="compromisoSection right">
						<div className="compromisoText">
							<h1>
								<Trans>Bienestar animal</Trans>
							</h1>
							<div>
								<p>
									<Trans>
										Desde que comenzamos este proyecto, nos dimos cuenta de que
										no nos sentíamos del todo cómodos con los procesos de
										producción de la industria de la proteína animal.
									</Trans>
								</p>
								<p>
									<Trans>
										Por esto, nos hemos asegurado de que todas las proteínas no
										vegetales que encontrarás en Begin, sin excepción, cumplen
										con las directrices establecidas por la OIE sobre Bienestar
										Animal acerca de su libertad y correcta alimentación a base
										de pasto.
									</Trans>
								</p>
							</div>
						</div>
						<a href="/pdf.pdf" className="pdfLink">
							<Trans>Conocer más +</Trans>
							<Subrayado2 />
						</a>
					</div>
				</Parallax>
			</div>

			<Pilares />
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
